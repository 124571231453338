import { AnimatePresence } from "framer-motion";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import retina from "retinajs";

// Context
import GlobalContext from "./Context/Context.jsx"; // Ensure extension is correct

// Components
import ScrollToTopButton from "./Components/ScrollToTop.jsx"; // Added .jsx extension

// Lazy loaded components
const HomeEN = lazy(() => import("./Pages/en/HomeEN.jsx")); // Added .jsx extension
const AboutMePageEN = lazy(() => import("./Pages/en/AboutMePageEN.jsx")); // Added .jsx extension
const PricingPackagesPageEN = lazy(() =>
  import("./Pages/en/PricingPackagesPageEN.jsx")
); // Added .jsx extension
const BlogPage = lazy(() => import("./Pages/en/BlogPage.jsx"));

const BlogPostDetailPage = lazy(() =>
  import("./Pages/en/BlogPostDetailPage.jsx")
);

const ArticlePage = lazy(() => import("./Pages/en/ArticlePage.jsx"));

const ArticlePostDetailPage = lazy(() =>
  import("./Pages/en/ArticlePostDetailPage.jsx")
);
const ContactUsModernPageEN = lazy(() =>
  import("./Pages/en/ContactUsModernPageEN.jsx")
); // Added .jsx extension
// const MasterClassEN = lazy(() => import("./Pages/en/MasterClassEN.jsx")); // Added .jsx extension
const WeightManagementEN = lazy(() =>
  import("./Pages/en/WeightManagementEN.jsx")
); // Added .jsx extension
const DiabetesNutritionEN = lazy(() =>
  import("./Pages/en/DiabetesNutritionEN.jsx")
); // Added .jsx extension
const AutoimmuneNutritionEN = lazy(() =>
  import("./Pages/en/AutoImmuneNutritionEN.jsx")
); // Added .jsx extension
// const NutritionGuidesEN = lazy(() =>
//   import("./Pages/en/NutritionGuidesEN.jsx")
// ); // Added .jsx extension
// const ScientificResearchEN = lazy(() =>
//   import("./Pages/en/ScientificResearchEN.jsx")
// ); // Added .jsx extension
// const QuestionnaireEN = lazy(() => import("./Pages/en/QuestionnaireEN.jsx")); // Added .jsx extension
const PrivacyPolicy = lazy(() => import("./Components/PrivacyPolicy.jsx")); // Added .jsx extension
const TermsConditions = lazy(() => import("./Components/TermsConditions.jsx")); // Added .jsx extension

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const location = useLocation();
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({ el: null, isOpen: false });

  useEffect(() => {
    window.addEventListener("load", () =>
      retina(document.querySelectorAll("img"))
    );
    return () =>
      window.removeEventListener("load", () =>
        retina(document.querySelectorAll("img"))
      );
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities.js").then((module) => {
        // Added .js extension
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    setFooterHeight(0);
    setCustomModal({ ...customModal, el: null, isOpen: false });
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
          <ScrollToTopButton />
          <AnimatePresence mode="wait">
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<HomeEN />} />
                <Route path="/about-me" element={<AboutMePageEN />} />
                <Route path="/pricing" element={<PricingPackagesPageEN />} />
                <Route path="/blog" element={<BlogPage />} />
                <Route path="/blog/:slug" element={<BlogPostDetailPage />} />
                <Route path="/article" element={<ArticlePage />} />
                <Route
                  path="/article/:slug"
                  element={<ArticlePostDetailPage />}
                />
                <Route path="/contact" element={<ContactUsModernPageEN />} />
                {/* <Route
                  path="/resources/masterclass"
                  element={<MasterClassEN />}
                />
                <Route
                  path="/resources/nutrition-guides"
                  element={<NutritionGuidesEN />}
                />
                <Route
                  path="/resources/scientific-research"
                  element={<ScientificResearchEN />}
                />
                <Route
                  path="/resources/questionnaire"
                  element={<QuestionnaireEN />}
                /> */}
                <Route
                  path="/solutions/weight-management"
                  element={<WeightManagementEN />}
                />
                <Route
                  path="/solutions/diabetes-nutrition"
                  element={<DiabetesNutritionEN />}
                />
                <Route
                  path="/solutions/autoimmune-nutrition"
                  element={<AutoimmuneNutritionEN />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-conditions" element={<TermsConditions />} />
               
              </Routes>
            </Suspense>
          </AnimatePresence>
        </main>
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
